



















































































































































import { Component, Vue } from 'vue-property-decorator';
import { helpers, minLength, required } from 'vuelidate/lib/validators';
import AlertComponent from '@/components/AlertComponent.vue';
import { validationMixin } from 'vuelidate';
import AlertType from '@/utils/enums/AlertType';
import { Getter, namespace } from 'vuex-class';
import ButtonComponent from '@/components/ButtonComponent.vue';
import InputText from '@/components/InputText.vue';
import EmailResponse from '@/utils/types/sign-in/EmailResponse';
import AuthStep from '@/utils/enums/AuthStep';
import Community from '@/models/graphql/Community';
import { EMAIL } from '@/utils/constants/Regex';
import AuthProvider from '@/models/graphql/AuthProvider';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import CheckboxSwitchComponent from '@/components/CheckboxSwitchComponent.vue';
import CommunityUser from '@/models/graphql/CommunityUser';
import AuthPageGuard from '@/guards/AuthPageGuard';

const authenticationStore = namespace('AuthenticationStore');
const authProviderStore = namespace('AuthProviderStore');
const emailValidation = helpers.regex('email', EMAIL);

@Component({
  components: {
    ButtonComponent,
    InputText,
    AlertComponent,
    ButtonIconComponent,
    CheckboxSwitchComponent,
  },
  mixins: [validationMixin],
  validations: {
    form: {
      email: {
        required,
        emailValidation,
      },
      password: {
        required,
        minLength: minLength(8),
        valid(value: string): boolean {
          const containsUppercase = value ? value.match(/[A-Z]/) : false;
          const containsLowercase = value ? value.match(/[a-z]/) : false;
          const containsNumber = value ? value.match(/[0-9]/) : false;
          return !!containsUppercase && !!containsLowercase && !!containsNumber;
        },
      },
    },
  },
  beforeRouteEnter: AuthPageGuard.beforeEnter,
})
export default class SignInSignUp extends Vue {
  @Getter
  private readonly community!: Community;

  @authenticationStore.State
  private readonly authEmail!: string | null;

  @authenticationStore.Action
  private checkEmail!: (payload: {
    communityCode: string; email: string;
  }) => Promise<EmailResponse>;

  @authenticationStore.Mutation
  private setAuthStep!: (step: AuthStep | null) => void;

  @authenticationStore.Mutation
  private setAuthEmail!: (email: string | null) => void;

  @authenticationStore.Action
  private logout!: () => Promise<void>;

  @authProviderStore.Action
  private loadAuthProviders!: () => Promise<AuthProvider[]>;

  @authenticationStore.Action
  private createAccount!: (model: CommunityUser) => Promise<CommunityUser | undefined>;

  @Getter
  private readonly isUnifyExhibitorPortal!: boolean;

  private alertType = AlertType;

  private isLoading = false;

  private emailError = false;

  private showError = false;

  private emailServiceError = false;

  private disableEmail = false;

  private disableSubmit = true;

  private passwordError = false;

  private authProviders: AuthProvider[] | null = null;

  private form = {
    email: '',
    password: '',
    agreed: false,
  };

  private get signUpText(): string {
    if (this.$te('app.sign-in.sign-up.sign-up-text')) {
      return `${this.$t('app.sign-in.sign-up.sign-up-text')}`;
    }
    return '';
  }

  created(): void {
    if (this.authEmail) {
      this.form.email = this.authEmail;
    }
    this.loadAuthProviders()
      .then((response) => {
        if (response) {
          this.authProviders = response;
        }
      });
  }

  private emailValidation(): void {
    if (this.$v) {
      this.emailError = this.$v.form.email ? this.$v.form.email.$invalid : false;
    }
  }

  private setShowError(): void {
    this.showError = true;
  }

  private onBlur(): void {
    if (this.showError) {
      this.onChange('');
    }
  }

  private onChange(fieldType: string): void {
    if (this.$v) {
      this.passwordError = this.$v.form.password ? this.$v.form.password.$invalid : false;
      if (fieldType === 'agreed') {
        this.showError = true;
      }
    }
    this.disabledSubmit();
  }

  private disabledSubmit(): void {
    this.disableSubmit = this.emailError || this.passwordError || !this.form.agreed;
  }

  private onAuthProviderClick(id: number | undefined): void {
    if (id) {
      // eslint-disable-next-line max-len
      window.location.href = `${process.env.VUE_APP_SERVICES_ACCOUNT_URL}oauth/start-flow?community_code=${this.community.code}&login_provider_id=${id}`;
    }
  }

  private onGuestClick(): void {
    this.logout()
      .then(() => {
        this.$router.push({ path: '/' });
      });
  }

  private onSubmit(): void {
    this.emailValidation();
    this.onChange('password');
    this.disableEmail = true;
    this.isLoading = true;
    this.setShowError();
    this.emailServiceError = false;
    if (this.form.email
      && this.form.email !== ''
      && !this.$data.emailError
      && this.community.code
      && !this.passwordError
      && this.form.agreed
    ) {
      this.checkEmail({
        communityCode: this.community.code,
        email: this.form.email,
      })
        .then((response: EmailResponse) => {
          if (response.success) {
            this.setAuthEmail(this.form.email);
            if (response.canProceedWithAccountCreation) {
              this.createAccount(
                {
                  email: this.form.email,
                  passwordHash: this.form.password,
                  firstName: '',
                  lastName: '',
                  name: '',
                } as CommunityUser,
              )
                .then((res: CommunityUser | undefined) => {
                  if (res) {
                    this.$router.push({ name: 'enter-information' });
                  } else {
                    this.emailError = true;
                  }
                  this.isLoading = false;
                })
                .catch(() => {
                  this.emailServiceError = true;
                  this.isLoading = false;
                });
            } else if (response.canProceedWithPasswordLogin || response.canProceedWithMagicLinkViaEmail) {
              this.emailError = true;
              this.isLoading = false;
            } else if (!response.canProceedWithAccountCreation
              && !response.canProceedWithPasswordLogin
              && !response.canProceedWithMagicLinkViaEmail
            ) {
              this.$router.push({ name: 'no-sign-up' });
            } else {
              this.emailError = true;
              this.isLoading = false;
            }
          } else {
            this.emailError = true;
            this.isLoading = false;
          }
          this.disableEmail = false;
        })
        .catch(() => {
          this.emailServiceError = true;
          this.disableEmail = false;
          this.isLoading = false;
        });
    } else {
      this.disableEmail = false;
      this.isLoading = false;
    }
  }
}
